import { Employee } from "./Employee";
import { Order } from "./Order";
import { Branch } from "./Branch";

export class TripDailyLog {
    id: number
    tripId: string;
    branch: Branch[];
    branchNumber: string;
    branchName: string;
    vehicleId: number;
    vehicleName: string; 
    mileageTravel: number;
    activityDate: Date;
    startTime: string; 
    endTime: string;
    employeeList: Employee[];
    orderList: Order[];
    userName: string;
    createdOn : string;
    createdBy : string;
    lastUpdatedUser: string;
    lastUpdatedOn : string;
    tripStartDate : Date;
    tripEndDate  : Date;
    crewCount: number;
    orderCount: number;
    payType: string;
    batchId: string;
    tripHour: number;
}
