import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from 'app/services/auth.service';
import { MaintenanceService } from 'app/services/maintenance.service';

import { UserInfo } from 'app/model/UserInfo';
import { Branch } from 'app/model/Branch';
import { Employee } from 'app/model/Employee';
import { Order } from 'app/model/Order';
import { Vehicle } from 'app/model/Vehicle';
import { PrimaryJob } from 'app/model/PrimaryJob';
import { TripDailyLog } from 'app/model/TripDailyLog';
import { Carlendar } from 'app/model/Carlendar';
import { BranchLicenseTypeRate } from 'app/model/BranchLicenseTypeRate';
import { Product } from 'app/model/Product';
import { MileageRate } from 'app/model/MileageRate';
import { EmployeeHours } from 'app/model/EmployeeHours';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
    private userDetails;
    isIEBrowser : BehaviorSubject<boolean>;
    userInfoObj : UserInfo;

    branchList: Branch[] = [];
    defaultBranch:any = [];
    jobTitleList : PrimaryJob[] = [];
    defaultJobList: PrimaryJob[] = [];
    licenseClassList: any = [];
    defaultLicenseList: any = [];
    yearList: any = [];
    weekPeriodList: Carlendar[] = [];
    productList: Product[] = [];
    productCate: Product[] = [];
    defaultProductCate: Product[] = [];
    userList: UserInfo[] = [];

    // selected employee hourly view
    empHour: EmployeeHours; 

    //Trip Master 
    dailyLog: TripDailyLog;
    isTripsLookUp : boolean = true;
    isDisplayMenu: boolean = false;
    tabIndex : number = 0;
    batchID: string = '';

    // Trip Addon Entry
    action : string = 'New';
    tripSelectedBranch: Branch[] = [];
    selectedEmployee : Employee[] = [];
    selectedOrder: Order[] = [];
    selectedVehicle: Vehicle[] = [];
    employeeList : Employee[] = [];
    orderList : Order[] = [];
    tripNumber : string;

    //License Type
    branchLicecnseTypeRate: BranchLicenseTypeRate;

    //Mileage
    branchMileageRate: MileageRate;

    //Report
    reportStartDate: Date;
    reportEndDate: Date;

    //Payroll
    payRollStartDate: Date;
    payRollEndDate: Date;

    constructor(private authService: AuthService, 
      private maintenanceService: MaintenanceService) { 
      this.isIEBrowser = new BehaviorSubject(true);
      this.userInfoObj = new UserInfo;

      this.empHour = new EmployeeHours;

      this.branchList = [];
      this.defaultBranch = [];
      this.jobTitleList = [];
      this.defaultJobList = [];
      this.licenseClassList = [];
      this.weekPeriodList = [];
      this.defaultLicenseList = [];
      this.userList = [];

      this.dailyLog = new TripDailyLog;
      this.isTripsLookUp = true;
      this.isDisplayMenu = false;
      this.action = 'New';
      this.batchID = '';
      this.tabIndex = 0;
      this.tripSelectedBranch = [];
      this.selectedEmployee = [];
      this.selectedOrder = [];
      this.selectedVehicle = [];
      this.tripNumber = '';
      
      this.employeeList = [];
      this.orderList = [];

      this.branchLicecnseTypeRate = new BranchLicenseTypeRate;   
      this.branchMileageRate = new MileageRate;    

      this.reportStartDate = null;
      this.reportEndDate = null;

      this.payRollStartDate = null;
      this.payRollEndDate = null;
    }

    setUserInfo(item) 
    {
      this.userInfoObj.userName = item[0].userName;
      this.userInfoObj.email = item[0].email;
      this.userInfoObj.accessGroup = item[0].accessGroup;
      this.userInfoObj.assignedBranch = 'All';
    }
 
  getUserInfo(): UserInfo {
    return this.userInfoObj;
  }

  getMMDDYYYY(date) {
    if(date == null)
    {
      return "";
    }
      let today = new Date(date);
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();
      let sDate : string;
      sDate = dd.toString();
      let mMonth : string;
      mMonth = mm.toString();
  
      if (dd < 10)
        sDate = '0' + dd
      if (mm < 10)
        mMonth = '0' + mm
  
      return mMonth + '/' + sDate + '/' + yyyy ;
  }
  getHours(ms) {
    if(ms <= 0) { return 0; } 
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 60 / 60) % 24);
    return parseFloat(hours + '.' + minutes);
  }

  checkPageAccess(pageName): boolean
  {
    let isFullAccess: boolean = false; 
    switch(pageName)
    {
      case "trips":
      case "trips_dailylog":
        if(this.userInfoObj.accessGroup == "ADMIN" || this.userInfoObj.accessGroup == "MANAGER" || 
          this.userInfoObj.accessGroup == "OPERATION")
          { isFullAccess = true; }
          break;
      case "weeklyhours":
      case "hours":
        if(this.userInfoObj.accessGroup == "HR" || this.userInfoObj.accessGroup == "MANAGER"|| 
          this.userInfoObj.accessGroup == "OPERATION" || this.userInfoObj.accessGroup == "ADMIN")
          { isFullAccess = true; }
          break;
      case "employee": 
        if(this.userInfoObj.accessGroup == "HR" || this.userInfoObj.accessGroup == "ADMIN")
        { isFullAccess = true; }
        break;
      case "mileage" :
      case "licenseType": 
        if(this.userInfoObj.accessGroup == "MANAGER" || this.userInfoObj.accessGroup == "ADMIN")
        { isFullAccess = true; }
        break;
      default: 
        return isFullAccess;
    }

    return isFullAccess;
  }

  
}
