import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './Login/Login.component';
import { DefaultComponent } from './layouts/default/default.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes =[
  { path: 'login',component : LoginComponent , canActivate: [MsalGuard] },
  { path: '', 
  component: DefaultComponent, 
  children: 
        [{ path: '', 
           loadChildren: () => import('./layouts/default/default.module').then(m => m.DefaultModule)},
           { path: '**', 
           component: LoginComponent, 
           canActivate: [MsalGuard] },]}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
