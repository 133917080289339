import { Component, OnInit,  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { forkJoin } from 'rxjs';
import { environment } from 'environments/environment';

import { SpinnerService} from '../services/spinner.service';
import { ModelService } from '../services/ModelService.service';
import { AuthService } from 'app/services/auth.service';
import { MaintenanceService } from 'app/services/maintenance.service';

import { UserInfo } from 'app/model/UserInfo';
import { Branch } from 'app/model/Branch';


@Component({
  selector: 'app-Login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.css']
})
export class LoginComponent implements OnInit {
  userProfile : UserInfo[] = [];
  
  paramFormId : string; paramStatusId : number;
  constructor(private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    public spinner : SpinnerService, 
    private modelService : ModelService, 
    private msalService: MsalService, 
    private authService: AuthService, 
    private maintenanceService: MaintenanceService) { }

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  bufferValue = 75;
  url: string;
  isNativeLogin = false;
  name : string = environment.name;
  isProcessBar = false;
  ngOnInit() {
    
    this.url = this.router.url;
    setTimeout(()=>{ this.spinner.stopSpinner();}, 1000);
    if(this.name == 'production' || this.name == 'staging'){
      const account = this.msalService.getAccount();
      if(account)
      {
        if(account.idTokenClaims.preferred_username)
        {
          this.validateADUser(account.idTokenClaims.preferred_username);
        }
      }   
      this.isProcessBar = true;
    } 
    else //native login
    {
      this.isNativeLogin = true;
    }
  }

  validateADUser(emailId){
    debugger;
    this.userProfile = [];
    this.authService.getUserProfile(emailId, false).subscribe(userInfo => {
      if(userInfo)
      {
        this.modelService.branchList = [];
        this.modelService.defaultBranch = [];
        if(userInfo[0].accessGroup != null)
        {
          this.modelService.setUserInfo(userInfo)
          this.getAssingedBranch();
        }       
        else 
        {
          Swal.fire({
            title: 'Login Failed',
            text: "You aren't authorised to access this application, please contact your system administrator",
            icon: 'warning',
            confirmButtonColor: '#103355',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
             this.router.navigate(['/login']);
            }
          })
        }
      } 
      else 
      {
        Swal.fire({
          title: 'Login Failed',
          text: "You aren't authorised to access this application, please contact your system administrator",
          icon: 'warning',
          confirmButtonColor: '#103355',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
           this.router.navigate(['/login']);
          }
        })
      }
    });
  }
  
  onLogin(loginForm: NgForm) {
    debugger;
    this.spinner.showSpinner(); 
    this.http.get<any>("assets/json/users.json").subscribe((data)=>{
        this.spinner.stopSpinner();
        if(data){
          this.userProfile = data;
          let filterUserData = this.userProfile.filter(x => x.email.toUpperCase() == loginForm.value.userName.toUpperCase())
          if(filterUserData.length > 0)
          {
            this.modelService.branchList = [];
            this.modelService.defaultBranch = [];
            this.modelService.setUserInfo(filterUserData)
            this.getAssingedBranch();
          }
          else{
            Swal.fire({
              title: 'Login Failed',
              text: "User ( " + loginForm.value.userName + " ) is not authorised to access this application, please contact your system administrator",
              icon: 'warning',
              confirmButtonColor: '#103355',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
               this.router.navigate(['/login']);
              }
            })
          }        
        }
      }
    );
  }
  
  getAssingedBranch(){
    debugger;
    if (this.modelService.userInfoObj.accessGroup != 'HR' 
    && this.modelService.userInfoObj.accessGroup != 'SUPPORT' 
    && this.modelService.userInfoObj.accessGroup != 'ADMIN')
    {
      this.authService.getBranchUserMappping(this.modelService.userInfoObj.email).subscribe(branch =>{
        if(branch && branch.length > 0)
        {
          let branchArr : Branch[] = branch;
          let yFilter = branchArr.map(x => { return x.branchNumber; });
          this.modelService.userInfoObj.assignedBranch = yFilter.length > 0 ? yFilter.join(',') : 'All';
          this.maintenanceService.getPieceRateBranchList(this.modelService.userInfoObj.assignedBranch).subscribe(branchlist => 
          {
            if(branchlist)
            {
              this.modelService.branchList = branchlist; 
              this.modelService.defaultBranch = [];
              if (this.modelService.branchList.length > 0)
              for (let i = 0; i < this.modelService.branchList.length; i++) {
                if(i < 10) 
                {
                  this.modelService.defaultBranch.push(this.modelService.branchList[i]);
                }
              }
              else { this.modelService.defaultBranch = []; }
              this.spinner.stopSpinner();
              this.router.navigate(['/trips']);
            }
          });
        }
        else
        {
          Swal.fire({
            title: 'Login Failed',
            text: "You aren't authorised to access this application, please contact your system administrator",
            icon: 'warning',
            confirmButtonColor: '#103355',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
             this.router.navigate(['/login']);
            }
          })
        }
      });
    } 
    else 
    { 
      //const request1 =this.maintenanceService.getBranchList("All");
      const request1 =this.maintenanceService.getPieceRateBranchList("All");
      const request2 = this.maintenanceService.GetBranchMapping();
      if(typeof(request1) && typeof(request2))
      {
        forkJoin([request1,request2]).subscribe((response)=>{
          debugger;
            if (response){
              this.modelService.branchList = response[0];
              this.modelService.defaultBranch = response[1];
              let yFilter = this.modelService.defaultBranch.map(x => { return x.branchNumber; });
              this.modelService.userInfoObj.assignedBranch = yFilter.length > 0 ? yFilter.join(',') : 'All';
              this.spinner.stopSpinner();
              this.router.navigate(['/trips']);
            }
        });
      }
      else 
      {
        this.spinner.stopSpinner();
        Swal('Invalid data list', '', 'warning');
        return false;
      }
    }
  }

}
