export class MileageRate {
    action : string;
    id : number;
    branchNumber : string;
    branchName : string;
    startDate : string;
    endDate : string;
    threshold : number;
    rate : number;
    userName :number
}
