import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserInfo } from 'app/model/UserInfo';
import { Branch } from 'app/model/Branch';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  mainURL : string = environment.mainUrl;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
constructor(private http:HttpClient) { }

  getUserProfile(emailId, isNativeLogin) : Observable<UserInfo[]> {
    debugger;
    const baseURL: string = this.mainURL + "api/ADUser/GetUserProfile";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("EmailId",  emailId);
    queryParams = queryParams.append("IsNativeLogin", isNativeLogin );
    return this.http.get<UserInfo[]>(baseURL, {  headers: this.headers, params: queryParams })
  }

  getBranchUserMappping(userEmail) : Observable<Branch[]> {
    debugger;
    const baseURL: string = this.mainURL + "api/ADUser/GetBranchUserMapping";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("EmailId",  userEmail);
    return this.http.get<Branch[]>(baseURL, {  headers: this.headers, params: queryParams })
  }

}
