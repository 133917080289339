export class BranchLicenseTypeRate {
    id: number;
    branchNumber: string;
    branchName: string;
    licenseCode: string;
    msf : string;
    msfCarry : string;
    steel : string;
    ceilingCtn : string;
    bbp : string;
    userName : string;
    action: string;
    isChangd: boolean;
}
