export const environment = {
  name: 'production',
  production: true,
  clientId: '53cb8946-0c1c-4d05-b4a1-35a002258f96', 
  tenantId: '8db90014-ca66-493d-b1b1-aa9ce0ba9aed', 
  authority: 'https://login.microsoftonline.com/8db90014-ca66-493d-b1b1-aa9ce0ba9aed/', 
  //redirectUrl: 'https://piecerateprodwebapp.azurewebsites.net/#/login',
  //postLogoutRedirectUri: 'https://piecerateprodwebapp.azurewebsites.net/#/logout', 
  redirectUrl: 'https://piecerateprodwebapp.fbmsales.com/#/login',
  postLogoutRedirectUri: 'https://piecerateprodwebapp.fbmsales.com/#/logout',
  scopeUri : "api://cd64b71f-6f91-4b5f-a0fb-91c64ec819de/PieceRateApp-api-access", 
  uiClienId: '53cb8946-0c1c-4d05-b4a1-35a002258f96', 
  mainUrl : 'https://piecerateprodwebapi.azurewebsites.net/',
  isNativeLogin: false,
  apiKey : '72569303782241fe8871d5210897cd5c9026064ed2af4a5986960803cc6e8565'
};

