import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import {PortalModule} from '@angular/cdk/portal';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';
import { environment } from 'environments/environment';

import {
  AgmCoreModule
} from '@agm/core';

import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import { SpinnerService } from './services/spinner.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ModelService} from './services/ModelService.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {  } from '@angular/material/toolbar';
// import { NgxSpinnerModule } from 'ngx-spinner';              
// import { AppConfig } from './app.config';
// import { IAppConfig } from './model/app-config';

import { MsalModule, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { HeaderInterceptor } from 'app/interceptors/headerinterceptor';
// import { tap } from 'rxjs/operators';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './Login/Login.component';
import { MatDialogModule } from '@angular/material/dialog';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const protectedResourceMap: any =
  [
    [environment.mainUrl, environment.scopeUri]
  ];

  
@NgModule({
  imports: [
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    // ReactiveFormsModule,
    // HttpModule,
    ComponentsModule,
    MatRippleModule,
    MatSelectModule,
    RouterModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressBarModule, 
    MatProgressSpinnerModule,
    // NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    OverlayModule,
    PortalModule,
    MatSidenavModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.uiClienId,
        authority: environment.authority,
        redirectUri: environment.redirectUrl,
        postLogoutRedirectUri: environment.postLogoutRedirectUri,
        validateAuthority: true,
        navigateToLoginRequestUrl: false
      }, cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        framework: {
          isAngular: true
        },
  }, {
    popUp: false,
    consentScopes: [
      'user.read',
      'openid',
      'profile',
      environment.scopeUri
    ],
    protectedResourceMap: [
      ['https://graph.microsoft.com/v1.0/me', ['user.read']]
    ]
  })
  ],
  declarations: [						
    AppComponent,
      LoginComponent,
      LogoutComponent
   ],
  providers: [ SpinnerService, ModelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
  MsalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
