import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Branch } from 'app/model/Branch';
import { BranchUserMappedDetails } from 'app/model/branch-user-mapped-details';
import { UserInfo } from 'app/model/UserInfo';
import { BranchLicenseTypeRate } from 'app/model/BranchLicenseTypeRate';
import { Region } from 'app/model/Region';
import { PrimaryJob } from 'app/model/PrimaryJob';
import { DriverLicense } from 'app/model/DriverLicense';
import { Paycode } from 'app/model/Paycode';
import { Product } from 'app/model/Product';
import { MileageRate } from 'app/model/MileageRate';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  mainURL : string = environment.mainUrl;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http:HttpClient) { }

  getBranchList(branchNumber) : Observable<Branch[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetBranchList/";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BranchNumber",  branchNumber);
    return this.http.get<Branch[]>(baseURL, {headers : this.headers, params: queryParams});  
  }

  getPieceRateBranchList(branchNumber) : Observable<Branch[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetPieceRateBranchList/";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BranchNumber",  branchNumber);
    return this.http.get<Branch[]>(baseURL, {headers : this.headers, params: queryParams});  
  }

  getUserMappingList(branchId, userRole) : Observable<BranchUserMappedDetails[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetUserMappingList/";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BranchId", branchId );
    queryParams = queryParams.append("UserRole", userRole );
    return this.http.get<BranchUserMappedDetails[]>(baseURL, { headers: this.headers, params: queryParams });
  }

  deleteUserMappingList(deleteUserMappingList) : Observable<BranchUserMappedDetails>{
    const baseURL: string = this.mainURL + "api/maintenance/DeleteUserMappingList";
    const body=deleteUserMappingList; 
    return this.http.post<BranchUserMappedDetails>(baseURL, body, { headers: this.headers });
  }

  getUserList(emailId) : Observable<UserInfo[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetUserList";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("EmailId", emailId );
    return this.http.get<UserInfo[]>(baseURL, { headers: this.headers, params: queryParams });
  }

  upsertUserMapping(branchId, emailId, userRole, createBy) : Observable<any>{
    const baseURL: string = this.mainURL + "api/maintenance/UserMappingUpsert";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BranchId", branchId );
    queryParams = queryParams.append("EmailId", emailId );
    queryParams = queryParams.append("UserRole", userRole );
    queryParams = queryParams.append("CreateBy", createBy );
    return this.http.post<any>(baseURL, null, { headers: this.headers, params: queryParams });
  }

  getMappedUserValidation(branchId, emailId, userRole) : Observable<boolean>{
    const baseURL: string = this.mainURL + "api/maintenance/GetMappedUserValidation";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BranchId", branchId);
    queryParams = queryParams.append("EmailId", emailId);
    queryParams = queryParams.append("UserRole", userRole);
    return this.http.get<boolean>(baseURL, { headers: this.headers, params: queryParams });  
  }

  getBranchLicenseTypeRateList(region, branchId, licenseType) : Observable<BranchLicenseTypeRate[]>{
    const baseURL: string = this.mainURL + "api/maintenance/GetBranchLicenseTypeRateList";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Region", region );
    queryParams = queryParams.append("BranchId", branchId );
    queryParams = queryParams.append("LicenseType", licenseType );
    return this.http.get<BranchLicenseTypeRate[]>(baseURL, { headers: this.headers, params: queryParams });  
  }

  getRegionList() : Observable<Region[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetRegionList/";
    return this.http.get<Region[]>(baseURL, { headers: this.headers });
  }

  getPrimaryJobTitleList() : Observable<PrimaryJob[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetPrimaryJobTitleList/";
    return this.http.get<PrimaryJob[]>(baseURL, {headers : this.headers});  
  }

  getDriverLicenseList() : Observable<DriverLicense[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetDriverLicensenTypeList/";
    return this.http.get<DriverLicense[]>(baseURL, {headers : this.headers});  
  }

  GetDefaultJobTitleList() : Observable<PrimaryJob[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetDefaultJobTitleList/";
    return this.http.get<PrimaryJob[]>(baseURL, { headers: this.headers });
  }

  UpsertBranchLicenseTypeList(changedBranchList, userName) : Observable<BranchLicenseTypeRate>{
    debugger;
    const baseURL: string = this.mainURL + "api/maintenance/UpsertBranchLicenseTypeRate";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("UserName", userName )
    const body=changedBranchList; 
    return this.http.post<BranchLicenseTypeRate>(baseURL, body, { headers: this.headers, params: queryParams  });
  }

  GetPaycodeList() : Observable<Paycode[]>{
    const baseURL: string = this.mainURL + "api/maintenance/GetPaycode";
    return this.http.get<Paycode[]>(baseURL, { headers: this.headers });
  }

  getBranchLicenseTypeValidation(branchNumber, licenseType) : Observable<boolean>{
    const baseURL: string = this.mainURL + "api/maintenance/GetBranchLicenseTypeValidation";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BranchNumber", branchNumber );
    queryParams = queryParams.append("LicenseType", licenseType );
    return this.http.get<boolean>(baseURL, { headers: this.headers, params: queryParams });  
  }

  getDefaultProductCategory() : Observable<Product[]>
  {
    const baseURL: string = this.mainURL + "api/maintenance/GetDefaultProductCat"
    return this.http.get<Product[]>(baseURL, { headers: this.headers });  
  }

  getProductCategory() : Observable<Product[]>
  {
    const baseURL: string = this.mainURL + "api/maintenance/GetProductCategory"
    return this.http.get<Product[]>(baseURL, { headers: this.headers });  
  }

  getProductList(productCate) : Observable<Product[]>
  {
    const baseURL: string = this.mainURL + "api/maintenance/GetProductList"
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Category", productCate);
    return this.http.get<Product[]>(baseURL, { headers: this.headers, params: queryParams });  
  }

  getBranchMileageRate(branchNumber, startDate, endDate) : Observable<MileageRate[]>
  {
    const baseURL: string = this.mainURL + "api/maintenance/GetBranchMileageRate"
    let queryParams = new HttpParams();
    queryParams = queryParams.append("BranchNumber", branchNumber);
    queryParams = queryParams.append("StartDate", startDate);
    queryParams = queryParams.append("EndDate", endDate);
    return this.http.get<MileageRate[]>(baseURL, { headers: this.headers, params: queryParams });  
  }

  getMileageMaxMinDate() : Observable<MileageRate[]>
  {
    const baseURL: string = this.mainURL + "api/maintenance/GetMileageMaxMinDate"
    return this.http.get<MileageRate[]>(baseURL, { headers: this.headers });  
  }

  getBranchMileageValidation(action, id, branchNumber, startDate, endDate) : Observable<boolean>{
    const baseURL: string = this.mainURL + "api/maintenance/GetBranchMilageRateValidation";
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Action", action);
    queryParams = queryParams.append("Id", id);
    queryParams = queryParams.append("BranchNumber", branchNumber);
    queryParams = queryParams.append("StartDate", startDate);
    queryParams = queryParams.append("EndDate", endDate);
    return this.http.get<boolean>(baseURL, { headers: this.headers, params: queryParams });  
  }

  UpsertBranchMileageRate(mileageRate) : Observable<MileageRate>{
    debugger;
    const baseURL: string = this.mainURL + "api/maintenance/UpsertBranchMileageRate";
    const body=mileageRate; 
    return this.http.post<MileageRate>(baseURL, body, { headers: this.headers });
  }

  GetBranchMapping() : Observable<Branch[]> {
    const baseURL: string = this.mainURL + "api/maintenance/GetBranchMapping/";
    return this.http.get<Branch[]>(baseURL, { headers: this.headers});
  }

  getGroupMemberList() : Observable<UserInfo[]> {
    const baseURL: string = this.mainURL + "api/ADUser/GetGroupMembers";
    let queryParams = new HttpParams();
    return this.http.get<UserInfo[]>(baseURL, { headers: this.headers, params: queryParams });
  }
}
