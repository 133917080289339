import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import 'rxjs/add/operator/filter';
import { Router} from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { SidenavService } from 'app/services/sidenav.service';
import { ModelService } from 'app/services/ModelService.service';
import { MsalService } from '@azure/msal-angular';
import { UserInfo } from 'app/model/UserInfo';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {
  public onSideNavChange: boolean;
  sideBarOpen = true;
  public userDetails : UserInfo;
 isShowing = false;

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  constructor(public location: Location, private router: Router, private _sidenavService: SidenavService, 
            private modelService : ModelService, private msalService: MsalService, 
            private spinnerService: SpinnerService) {
      this.userDetails = this.modelService.getUserInfo();
      this._sidenavService.sideNavState$.subscribe( res => {
        this.onSideNavChange = res;
      })
      this.spinnerService.stopSpinner();
      
  }

  ngOnInit() {
    if(typeof(this.modelService.userInfoObj.userName) == 'undefined' || this.modelService.userInfoObj.userName == '')
    {
      this.router.navigate(['/login']);
    } else { this.isShowing = true; }
    const account = this.msalService.getAccount();
    if(!account)
    {
    this.router.navigate(['/login']);
    } else { this.isShowing = true; }
  }
   

  isMaps(path){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      titlee = titlee.slice( 1 );
      if(path == titlee){
          return false;
      }
      else {
          return true;
      }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      const ps = new PerfectScrollbar(elemMainPanel);
      ps.update();
    }
  }
  isMac(): boolean {
      let bool = false;
      if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
          bool = true;
      }
      return bool;
  }

}

