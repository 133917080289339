export class EmployeeHours {
    id : number;
    employeeNumber : string;
    employeeFullName : string;
    branchNumber : string;
    branchName : string;
    jobTitle: string;
    workDate : string;
    payCode : string;
    workHour : string;
    batchID : string;
}
